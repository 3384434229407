<!--  登录注册   -->
<template>
  <div class="container">
    <div class="cover"></div>
    <div id="login_show_box" style="opacity: 1; top: 250px;text-align: left;">
      <div class="reg_zone">
        <span class="close7" @click="close">
          <img src="https://passport.zhulong.com/img/loginimg/x.png">
        </span>
        <div class="rzone-t">
          <h1></h1>
          <!-- <img
            src="https://passport.zhulong.com/img/regimg/logo-zl.png"
            alt=""
          > -->
        </div>
        <!-- <ul id="btns">
          <li
            :class="phoneOrnum ? 'login-active' : 'no-active'"
            @click=";(phoneOrnum = true), choosetypt(1)"
          >
            手机快速通道
          </li>
          <li
            :class="phoneOrnum ? 'no-active' : 'login-active'"
            @click=";(phoneOrnum = false), choosetypt(2)"
          >
            账号登录
          </li>
        </ul> -->
        <div class="login_denglu_left reg-form" style="padding-top: 35px">
          <ul id="content1">
            <li :class="phoneOrnum ? 'show' : 'noshow'">
              <div class="skin">
                <label for="tel">手机号:</label>
                <div class="inp-out bor_red">
                  <i
                    class="iconfont icon-comiisyonghuming-copy icon1"
                  >&#xe622;</i>
                  <input
                    id="tel"
                    v-model.trim="phone"
                    class="bor_red"
                    placeholder="请输入手机号"
                    style="width: 210px; padding-left: 68px"
                    @blur="testphone"
                  >
                  <input
                    v-model="diqu"
                    style="width: 40px; margin-left: 10px"
                    type="text"
                    placeholder="+86"
                    class="diqu"
                  >
                </div>
                <div v-show="phone_err" class="popo">
                  <span class="pol"></span>
                  <span class="pom">
                    <img
                      src="https://passport.zhulong.com/img/loginimg/warn.png"
                    >
                    请输入正确的手机号
                  </span>
                  <span class="por"></span>
                </div>
              </div>
              <div class="skin">
                <label for="tel">验证码:</label>
                <div
                  class="inp-out bor_red error-red"
                  style="width: 192px; border: 1px solid rgb(238, 238, 238)"
                >
                  <i class="iconfont icon-mima-copy icon2"></i>
                  <input
                    id="Ucode"
                    v-model="ucode"
                    class="bor_red"
                    type="text"
                    style="width: 150px"
                    placeholder="请输入验证码"
                  ><br>
                </div>
                <span
                  v-show="iscode"
                  id="Getcode"
                  class="pray"
                  @click="getcode"
                >获取验证码</span>
                <span
                  v-show="againcode"
                  id="Getcode1"
                  class="pray"
                >{{ code_text }}</span>
                <div v-show="code_err" class="popo">
                  <span class="pol"></span>
                  <span
                    class="pom error"
                  ><img
                    src="https://passport.zhulong.com/img/loginimg/warn.png"
                  >请输入正确的验证码</span>
                  <span class="por"></span>
                </div>
              </div>
            </li>

          </ul>
          <div
            style="padding: 0 60px 0 50px; line-height: 30px; color: #999999"
          >
            <input id="Remember" type="radio" name="isremember" value="1">
            <!-- <label
              class="label_gai"
              style="padding-left: 0; background-size: 13px"
              @click="islong"
            ><i
               v-show="long"
               class="iconfont icon-weixuanzhong wei"
               style="
                  font-size: 12px;
                  color: #ee2e2e;
                  padding-left: 1px;
                "
             ></i>
              <i
                v-show="little"
                class="iconfont icon-xuanzhong wei"
                style="font-size: 12px; color: #ee2e2e; padding-left: 1px"
              ></i>&nbsp;我的电脑可长期登录</label> -->
            <!-- <a
              href="https://passport.zhulong.com/findpwd/findpwd"
              class="forgetpass"
              style="display: inline"
            >忘记密码？</a> -->
            <div>
              <a
                href="javascript:void(0);"
                class="denglu_btn"
                style="margin-top: 10px; float: left"
                @click="loginbtn()"
              >绑定手机号</a>
            </div>
          </div>
          <!-- <p class="login-intr">
            未注册手机验证后自动登录，注册即代表同意
            <router-link :to="{path:'/agreement'}">《注册协议》</router-link>
          </p> -->
          <div class="others-login">
            <p style="text-align: center; padding-bottom: 10px; color: #999;display:none">
              <img
                style="vertical-align: middle"
                src="https://passport.zhulong.com/img/loginimg/zuo.png"
              >
              社交账号登录
              <img
                style="vertical-align: middle"
                src="https://passport.zhulong.com/img/loginimg/you.png"
              >
            </p>
            <div
              style="
                width: 180px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                display:none
              "
            >
              <a
                class="qq_h"
                href="https://passport.zhulong.com/thirdparty/qqlogin"
              ><img
                src="https://passport.zhulong.com/img/loginimg/qq0.png"
                title="QQ登录"
                alt="QQ登录"
              ></a>
              <a
                class="weixin_h"
                href="https://passport.zhulong.com/thirdparty/wechatlogin"
              ><img
                src="https://passport.zhulong.com/img/loginimg/weixin0.png"
                title="微信登录"
                alt="微信登录"
                style=""
              ></a>
            </div>
          </div>
        </div>
        <div class="login_degnlu_right" style="display:none">
          <img id="downappimg" src="@/assets/appcode.jpg" alt="扫码下载">
          <p>
            <span style="color: #ee2e2e">
              {{ qrcode_des }}
              <!-- 请扫码下载筑龙学社APP -->
            </span>
          </p>
          <!-- <p>请使用<span style="color: #ee2e2e">筑龙学社APP</span>扫描登录</p>
          <p>或扫码下载APP</p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/weblogin.css'
import Cookies from 'js-cookie'
import { signInByQrcode, loginForApp, bindMobile, sendMobileCodeNew } from '@/api/login.js'
import { JSEncrypt } from 'jsencrypt'
export default {
  data() {
    return {
      phoneOrnum: true,
      uuid: this.$route.query.uuid,
      access_token: this.$route.query.access_token,
      phone: '',
      diqu: '+86',
      ucode: '',
      num: '',
      password: '',
      newcode: '',
      phone_err: false,
      code_err: false,
      is_see: true,
      type: 1,
      account_name: '',
      mima: '',
      code: '',
      appcode: '',
      errnum: false,
      passworderr: false,
      cansend: false,
      code_text: '',
      ticket: '',
      randstr: '',
      iscode: true,
      againcode: false,
      timer: null,
      s: 60,
      long: false,
      little: true,
      is_long: false,
      isUcode: false,
      codeimg: '',
      timelogin: null,
      redirect: decodeURIComponent(this.$route.query.redirect), // 来自哪个路由,
      userName: this.$route.query.username,
      qrcode_url: '',
      qrcode_des: ''

    }
  },
  computed: {},

  created() {
    this.getlogin()
    // this.listenlogin()
  },
  methods: {
    onSwitch() {
      this.codeimg = this.codeimg.split('?')[0] + `?r = ${Math.random()}`
    },
    getlogin() {
      signInByQrcode().then((res) => {
        // console.log(res, '扫码登录')
        // this.appcode = res.result
        const data = res.result
        this.qrcode_url = data.qrcode_url
        this.qrcode_des = data.qrcode_des
      })
    },
    encrypt(msg) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrWVnSOu9m7O8X3taQGXzVlB9B0Gw1Mvbc0MxKZOxT8SlQVB1Krpu3KfuoxgGE1TikX/JkYJf+D4tTqENqk5pnSZc784gWZPEs2O+uz5R/8Ay8qP06uHDzw1oGDrpo8wxWQ7Ae2IwE2gTDtpcyg8NUJp14uYwsvA47iDpXHGmPxQIDAQAB-----END PUBLIC KEY-----'
      )
      return encrypt.encrypt(msg)
    },
    close() {
      this.$emit('close', false)
      window.location.href = this.redirect
    },
    getcode() {
      if (this.cansend === true) {
        const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
        // 生成一个滑块验证码对象
        const that = this
        const captcha = new TencentCaptcha(appid, function(res) { // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
          if (res.ret === 0) {
          // 成功，传递数据给后台进行验证
            that.iscode = false
            that.againcode = true
            that.randstr = res.randstr
            that.ticket = res.ticket
            that.gettime()
            var info = {
              mobile: that.phone,
              ticket: that.ticket,
              randstr: that.randstr,
              code_type: 2
            }
            sendMobileCodeNew(info).then(res => {
              console.log(res, '手机号绑定')
              if (res.errNo != 0) {
                that.$message.error(res.msg)
              }
            })
            // var info = {
            //   agency_id: '2',
            //   //   app_id: 50000,
            //   app_id: 1,
            //   guoji: that.diqu,
            //   mobile: that.phone,
            //   ticket: that.ticket,
            //   randstr: that.randstr
            // }
            // sendMobileCode(info).then(res => {
            //   console.log(res, '手机号登录')
            // })
          } else {
          // 提示用户完成验证
          }
        })
        // 滑块显示
        captcha.show()
      }
    },
    choosetypt(type) {
      this.type = type
      if (this.type === 1) {
        this.account_name = this.phone
      } else if (this.type === 2) {
        this.account_name = this.num
      }
    },
    loginbtn() {
      // if (this.type === 1) {
      this.account_name = this.phone
      this.mima = ''
      this.code = this.ucode
      // } else if (this.type === 2) {
      //   this.code = this.newcode
      //   this.account_name = this.num
      //   this.mima = this.encrypt(this.password)
      // }
      // var info = {
      //   account_name: this.account_name,
      //   password: this.mima,
      //   code: this.code,
      //   // agency_id: '',
      //   app_id: '1',
      //   platform_code: '1',
      //   type: this.type,
      //   is_admin: '0',
      //   username: this.userName
      // }
      // signIn(info).then(res => {
      //   console.log(res.errNo)
      //   if (res.errNo === 0) {
      //     // 登录成功
      //     console.log(res, '登录成功')
      //     this.passworderr = false
      //     // this.close()
      //     Cookies.set('uid', res.result.uid, { expires: 7 })
      //     Cookies.set('username', res.result.username, { expires: 7 })
      //     this.$router.go(0)
      //     if (this.redirect !== '') { // 成功之后跳转页面
      //       // window.open(this.redirect)
      //       window.location.href = `${this.redirect}`
      //     }
      //   } else if (res.errNo === 161) {
      //     this.passworderr = true
      //   } else if (res.errNo === 1009) {
      //     this.isUcode = true
      //     this.codeimg = res.result
      //   } else if (res.errNo === 160) {
      //     this.$router.push({ path: '/bindPhone', query: { username: res.result.username }})
      //   }
      // })
      var info = {
        uuid: this.uuid,
        mobile: this.account_name,
        code: this.code,
        access_token: this.access_token
      }
      bindMobile(info).then(res => {
        if (res.errNo === 0) {
          // 登录成功
          console.log(res, '登录成功')
          this.passworderr = false
          // this.close()
          Cookies.set('uid', res.result.uid, { expires: 7 })
          Cookies.set('username', res.result.username, { expires: 7 })
          this.$router.go(0)
          if (this.redirect !== '') { // 成功之后跳转页面
            // window.open(this.redirect)
            window.location.href = `${this.redirect}`
          }
        } else if (res.errNo === 161) {
          this.passworderr = true
        } else if (res.errNo === 1009) {
          this.isUcode = true
          this.codeimg = res.result
        }
      })
    },
    testnum() {
      var reg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/g
      var _match = reg.test(this.num)
      if (!_match) {
        this.errnum = true
      } else {
        this.errnum = false
      }
      console.log(this.errnum)
    },
    testphone() {
      var reg = /^(1[0-9]{10})$/ // 手机号
      if (reg.test(this.phone)) {
        this.phone_err = false
        this.cansend = true
      } else {
        this.phone_err = true
        this.cansend = false
      }
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        this.code_text = '重发(' + this.s + ')'
        if (this.s === 0) {
          this.iscode = true
          this.againcode = false
          clearInterval(this.timer)
          this.s = 60
        }
      }, 1000)
    },
    islong() {
      this.is_long = !this.is_long
      if (this.is_long === false) {
        this.long = false
        this.little = true
      } else {
        this.little = false
        this.long = true
      }
    },
    listenlogin() {
      this.timelogin = setInterval(() => {
        // 某些定时器操作
        loginForApp().then(res => {
          // console.log(res)
          if (res.errNo === 0) {
            clearInterval(this.timelogin)
            Cookies.set('uid', res.result.uid, { expires: 7 })
            Cookies.set('username', res.result.username, { expires: 7 })
            location.reload()
            if (this.redirect !== '') { // 成功之后跳转页面
              // window.open(this.redirect)
              window.location.href = `${this.redirect}`
            }
          }
        }).catch(err => {
          clearInterval(this.timelogin)
          console.log(err)
        })
      }, 1000)
    }
  }
}
</script>
<style lang='less' scoped>
* {
  box-sizing: content-box;
}
.close7{
  cursor: pointer;
}
.rzone-t{
  // padding-left:56px !important;
  h1{
    width:110px;
    height:37px;
    background: url('../../assets/bindphone_logo.png')  no-repeat;
  }
}
</style>
